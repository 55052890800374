import { DeleteFilled } from "@ant-design/icons";
import { Button, Modal, Select } from "antd";
import { useMemo } from "react";
import { PolygonLabelActionType } from "../../contexts/polygonLabelAction";
import { usePolygonContext } from "../../contexts/polygonLabelContext";



const PolygonEditMenu = ({
    rectId, x, y, show, onClose }: {
        rectId: string, x: number, y: number, show: boolean,
        onClose: () => void,

    }) => {

    const { polygonState, dispatchPolygon } = usePolygonContext()
    const { classSummary, polygonRects, classifications } = polygonState;


    const currentRect = useMemo(() => {
        return polygonRects.find(rect => rect.id === rectId);
    }, [polygonRects, rectId]);

    const handleSelectClassification = (value: number) => {
        dispatchPolygon({
            type: PolygonLabelActionType.SET_POLYGON_CLASSIFICATION_ID,
            rectId: rectId,
            classificationId: value
        })
    };

    const handleDelete = (polygonId: string) => {
        dispatchPolygon({
            type: PolygonLabelActionType.DELETE_POLYGON_BY_ID,
            rectId: polygonId
        });
    }
    return (
        <div style={{
            position: 'absolute', left: x - 50, top: y, zIndex: 9999, display: show ? 'block' : 'none',
            width: 240, borderRadius: 5, background: 'white', flexDirection: 'column', gap: 5,
        }}>
            <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', padding: 10 }}>
                <h3>ClassSummary</h3>
                <Select
                    size='middle'
                    onChange={handleSelectClassification}
                    style={{ width: '100%' }}
                    value={currentRect?.classificationId}
                    options={[...classifications].map(item => {
                        return {
                            label: item.description,
                            value: item.id
                        }
                    })}
                />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: 15 }}>
                <Button onClick={onClose}>
                    OK
                </Button>
                <Button danger icon={<DeleteFilled />} onClick={() => handleDelete(rectId)}>
                    Delete
                </Button>
            </div>

        </div>
    )
}

export default PolygonEditMenu;


export const EmptyModal = ({
    rectId, onClose
}: {
    rectId: string, onClose: () => void
}) => {
    const { polygonState, dispatchPolygon } = usePolygonContext()
    const { polygonRects, classifications } = polygonState;

    const currentRect = useMemo(() => {
        return polygonRects.find(rect => rect.id === rectId);
    }, [polygonRects, rectId])

    const handleSelectClassification = (option: number) => {
        if (currentRect) {
            const updated = [...polygonRects].map(rect => {
                if (rect.id === rectId) {
                    return {
                        ...rect,
                        classificationId: option
                    }
                } else {
                    return rect
                }
            });
            return dispatchPolygon({
                type: PolygonLabelActionType.SET_POLYGON_DATASET_UPDATED,
                rects: [...updated]
            })
        }
    };

    return <Modal
        title='Pick a Class'
        open={Boolean(rectId)}
        onCancel={onClose}
        onOk={onClose}
        centered
    >
        <Select
            size='middle'
            onSelect={handleSelectClassification}
            style={{ width: '100%' }}
            value={currentRect?.classificationId}
            options={[...classifications].map(item => {
                return {
                    label: item.description,
                    value: item.id
                }
            })}
        />

    </Modal>
};