import { useCallback, useEffect, useMemo, useState } from 'react';
import { AnchorType, PolygonRect } from '../../contexts/polygonType';

import { CompressOutlined, DeleteFilled, EditOutlined, EyeInvisibleOutlined, EyeOutlined, FrownOutlined } from '@ant-design/icons';
import { Badge, Button, Modal, Popover, Select } from 'antd';
import React from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { PolygonLabelActionType } from '../../contexts/polygonLabelAction';
import { usePolygonContext } from '../../contexts/polygonLabelContext';


const error_message = 'Cropping failed. Image cannot be exported.';

const ImageModal = ({ open, onClose, imageUrl }: { open: boolean, onClose: () => void, imageUrl: string }) => {
    const handleDownloadImage = () => {
        const link = document.createElement('a');
        link.href = imageUrl;
        link.download = 'downloaded-image.jpg';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    return (
        <Modal
            title="Preview Image"
            centered open={open}
            onCancel={onClose}
            width={800}
            footer={[
                <Button onClick={handleDownloadImage}>Download</Button>
            ]}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <TransformWrapper

                    initialScale={1}
                    wheel={{ disabled: false }}
                    pinch={{ disabled: false }}
                    maxScale={5}
                >
                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                        <React.Fragment>
                            <TransformComponent>
                                <img src={imageUrl} alt='preview' width={500} />
                            </TransformComponent>

                        </React.Fragment>
                    )}
                </TransformWrapper>
            </div>
        </Modal>
    );
};


const findRectSide = (anchors: AnchorType[]) => {
    const pointX = anchors.map((anchor) => anchor.x);//取出所有x值
    const pointY = anchors.map((anchor) => anchor.y);//取出所有y值
    const minX = Math.min(...pointX);
    const maxX = Math.max(...pointX);
    const minY = Math.min(...pointY);
    const maxY = Math.max(...pointY);
    return {
        left: minX,
        right: maxX,
        top: minY,
        bottom: maxY,
    }
};

const PolygonCropBox = ({
    index,
    imageSource,
    polygon,
    onSelected,
    onDeletePolygon,
    onSelectPolygon,
    onVisiblePolygon,
    cancelSelect,
    labelColor

}: {
    index: number,
    imageSource: string,
    polygon: PolygonRect,
    onSelected: boolean
    onDeletePolygon: (polygonId: string) => void;
    onVisiblePolygon: (polygon: PolygonRect) => void;
    onSelectPolygon: (polygon: PolygonRect) => void,
    cancelSelect?: () => void,
    labelColor?: string
}) => {

    const { polygonState, dispatchPolygon } = usePolygonContext();
    const { classSummary, classifications } = polygonState;
    const [cropImageUrl, setCropImageUrl] = useState('');
    const [preview, setPreview] = useState(false);

    const [canvasError, setCanvasError] = useState('');


    const croppingCanvas = useCallback((anchors: AnchorType[]) => {
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.onload = (e: Event) => {
            const { naturalWidth: nw, naturalHeight: nh } = img;
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            if (!ctx) return;
            const { left, right, top, bottom } = findRectSide(anchors);
            const leftX = left * nw;
            const topY = top * nh;
            const width = (right - left) * nw;
            const height = (bottom - top) * nh;
            if (width <= 1 || height <= 1) {
                dispatchPolygon({ type: PolygonLabelActionType.SET_POLYGON_DELETE_RECT_BY_ID, rectId: polygon.id })
                return setCanvasError(error_message);
            } else {
                setCanvasError('');
            }
            canvas.width = nw
            canvas.height = nh
            ctx.save();
            ctx.beginPath();
            anchors.forEach(({ x, y }, index) => {
                if (index === 0) {
                    ctx.moveTo(x * nw, y * nh);
                } else {
                    ctx.lineTo(x * nw, y * nh);
                }
            });
            ctx.closePath();
            ctx.clip();
            // * !重要說明:圖像繪製從0,0開始，drawImage(原始圖像,-x,-y)，將圖像向左偏移對齊矩形的最小邊，這樣drawImage才會從矩形圍的區域開始畫!重要說明
            ctx.drawImage(img, -left, -top, nw, nh);
            ctx.restore();
            const croppedImage = ctx.getImageData(leftX, topY, width, height);
            ctx.clearRect(0, 0, nw, nh);
            const offsetX = canvas.width / 2 - width / 2;
            const offsetY = canvas.height / 2 - height / 2;
            ctx.putImageData(croppedImage, offsetX, offsetY);
            setCropImageUrl(canvas.toDataURL());
        };
        img.src = imageSource;

    }, [imageSource]);


    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault()
        return onSelectPolygon(polygon)
    };

    const handleSelectClassification = (value: number) => {
        dispatchPolygon({
            type: PolygonLabelActionType.SET_POLYGON_CLASSIFICATION_ID,
            rectId: polygon.id,
            classificationId: value
        })
    };

    useEffect(() => {
        if (polygon.anchors.length !== 0) {
            return croppingCanvas(polygon.anchors)
        };
    }, [croppingCanvas, polygon, imageSource]);


    const anchorDemo = useMemo(() => {
        return polygon.anchors.map(anchor => [anchor.x, anchor.y])
    }, [polygon]);

    const classLabel = useMemo(() => {
        //const obviousColor = (!polygonState.classifications) && labelColor;
        const target = classifications.find(ele => ele.id === polygon.classificationId);
        const summary = classSummary.find(item => item.class === target?.cid);
        return {
            label: summary?.classlabel,
            color: summary ? summary.color : undefined
        };

    }, [classifications, polygon, classSummary]);

    return (
        <>
            <ImageModal imageUrl={cropImageUrl} open={preview} onClose={() => setPreview(false)} />
            <Badge.Ribbon
                placement='start'
                text={canvasError ? <>Failed</> : <>{`${index + 1}: ${classLabel?.label || 'Empty Class'}`}</>}
                color={canvasError ? 'red' : classLabel?.color || labelColor}
            >
                <div
                    onMouseOver={(e) => {
                        e.currentTarget.style.cursor = "pointer"
                    }}
                    style={{
                        border: onSelected ? '3px solid rgb(188, 34, 230,0.8)' : '1px solid rgb(10,10,10,0.3)',
                        borderRadius: 10, height: 120,
                        position: 'relative', paddingTop: 20
                    }}>
                    <div style={{ position: 'absolute', right: -8, zIndex: 999, top: -6, border: '0px solid', display: 'flex', flexDirection: 'column' }} >
                        <Button
                            onClick={() => onDeletePolygon(polygon.id)}
                            icon={<DeleteFilled style={{ color: '#e73227 ' }} />}
                        />
                        <Button
                            disabled={onSelected}
                            onClick={() => onVisiblePolygon(polygon)}
                            icon={polygon.visible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                        />
                        <Button
                            onClick={() => setPreview(true)}
                            icon={<CompressOutlined />}
                        />
                        <Popover content={
                            <div style={{ width: 240, wordWrap: 'break-word', textAlign: 'center' }}>
                                <h3>Select ClassSummary</h3>
                                <Select
                                    size='middle'
                                    onChange={handleSelectClassification}
                                    style={{ width: '100%' }}
                                    value={polygon.classificationId}
                                    options={[...classifications].map(item => {
                                        return {
                                            label: item.description,
                                            value: item.id
                                        }
                                    })}
                                />
                            </div>
                        } title='Anchor JSON' trigger="click" placement='left'>
                            <Button
                                icon={<EditOutlined />}
                            />
                        </Popover>
                    </div>
                    <div style={{ overflow: 'hidden', width: '100%' }}>
                        {canvasError && <div style={{ width: '88%', height: 100, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                            <FrownOutlined style={{ fontSize: 24, color: 'red' }} />
                            <p>{canvasError}</p>
                        </div>}
                        {cropImageUrl ? <img
                            onClick={handleClick}
                            width={'80%'}
                            src={cropImageUrl}
                            alt='pre'
                        /> : <></>}
                    </div>
                </div>
            </Badge.Ribbon>
        </>

    );
};


export default PolygonCropBox