import { useCallback, useState } from 'react';
const usePolygonHistory = (limit: number,dependency:number|string|undefined) => {

    const [history, _setHistory] = useState<string[]>([]);

    const [dependValue, setDependValue] = useState<number | string | undefined>(dependency);

    const setHistory: (value: string) => void = useCallback((value: string) => {
         _setHistory(pre => {
            const updatedHistory = [...pre, value];
            if (updatedHistory.length > limit) {
                return [...updatedHistory.slice(1)];
            }
            return updatedHistory;
        });
    }, [ limit]);

    const undo: () => string = useCallback(() => {
        if (history.length === 1) {
            return history[0]
        } else {
            const currentStep = history.length - 1;
            const record = history[currentStep  -1];
            history.pop()
            _setHistory(history)
            return record
        };
    }, [history]);
    
    const initHistory = (value: string) => {
        return _setHistory([value]);
    };

    const updatedDependValue = (value:number | string | undefined) => setDependValue(value);
    
    return [history, setHistory, undo,dependValue,updatedDependValue, initHistory] as const;
};

export default usePolygonHistory

