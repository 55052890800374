import * as _ from "lodash";
import {
  Radio,
  Input,
  Button,
  RadioChangeEvent,
  Typography,
  Space,
  Tooltip,
  Modal,
  message,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import styled from "@emotion/styled";
import { CreateDatasetState } from "../../states/reducers/createDataset";
import { useEffect } from "react";
//import { fullFunction, abnormalFunction } from "../../urls/index";

const { Title } = Typography;

const ClassContainer = styled.div`
  display: flex;
`;

export interface classType {
  [key: string]: string;
}
export interface CreateDatasetFormProps { }
const CreateDatasetForm: React.FC<CreateDatasetFormProps> = () => {
  const {
    name,
    type,
    classDescriptions,
    isModalOpen,
    isPending,
    success,
    error,
  } = useTypedSelector((state) => state.createDataset);

  const {
    handleDatasetOnChange,
    cancelUpdateDataset,
    createDatasetAsync,
    clearCreateDatasetError,
    fetchDatasetsAsync,
  } = useActions();

  const { currentUser, currentPermission } = useTypedSelector((state) => state.auth);

  const userPermission = JSON.parse(atob(currentPermission));

  const {classifyFunction, segmentationFunction, keypointFunction, abnormalFunction, yoloFunction, angleFunction, smalldefectFunction, disgFunction} = userPermission;


  useEffect(() => {
    return () => {
      fetchDatasetsAsync();
    };
  }, [fetchDatasetsAsync]);

  useEffect(() => {
    if (success) {
      message.success(`dataset with name ${name} has created `);
    }
  }, [success, name]);

  useEffect(() => {
    if (error) {
      const isArr = _.isArray(error);
      if (isArr) {
        _.forEach(error, (err) =>
          message.error(err, 3, clearCreateDatasetError)
        );
      } else {
        message.error(error, 3, clearCreateDatasetError);
      }
    }
  }, [error, clearCreateDatasetError]);
  const onFormChange = (
    e: RadioChangeEvent | React.ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    if (key.includes("class")) {

      const classIndex = parseFloat(key.split("_")[1]);
      const updatedClasses = [...classDescriptions];
      updatedClasses[classIndex] = e.target.value;
      return handleDatasetOnChange("classDescriptions", updatedClasses);
    }
    return handleDatasetOnChange(
      key as keyof CreateDatasetState,
      e.target.value
    );
  };
  const handleAddClass = () => {
    handleDatasetOnChange("classDescriptions", [...classDescriptions, ""]);
  };
  const handleRemoveClass = (i: number) => {
    const updatedClasses = [...classDescriptions];
    updatedClasses.splice(i, 1);
    handleDatasetOnChange("classDescriptions", updatedClasses);
  };

  const handleOk = () => {

    handleDatasetOnChange("username", currentUser);

    if (name.trim().length < 4) {
      return message.error("dataset name must be longer than 4 characters");
    }

    if (type === "ABNORMAL") {
      const updatedClasses = ["OK", "NG"];
      handleDatasetOnChange("classDescriptions", updatedClasses);
    } else {
      if (
        type !== "DETECT" &&
        classDescriptions.filter((t) => t.trim().length > 0).length !==
        classDescriptions.length
      ) {
        return message.error("classification can not be blank");
      }
      if (type !== "DETECT" && classDescriptions.length < 2) {
        return message.error("at least two classifications");
      }
    }

    createDatasetAsync();
  };

  return (
    <Modal
      title="Create A Dataset"
      visible={isModalOpen}
      onOk={handleOk}
      confirmLoading={isPending}
      onCancel={cancelUpdateDataset}
      width={800}
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <Title level={5}>Dataset Type</Title>
        <Radio.Group value={type} onChange={(e) => onFormChange(e, "type")}>
          <Space direction="horizontal">
            <Radio.Button value="DETECT">DETECT</Radio.Button>
            {/* Function Remark */}
            {classifyFunction &&
              <Radio.Button value="CLASSIFY">CLASSIFY</Radio.Button>
            }
            <Radio.Button value="DETECT_AND_CLASSIFY">
              DETECT_AND_CLASSIFY
            </Radio.Button>
            
            {segmentationFunction &&
              <Radio.Button value="SEGMENTATION">
                SEGMENTATION
              </Radio.Button>
            }
            {keypointFunction &&
              <Radio.Button value="KEYPOINT">
                KEYPOINT
              </Radio.Button>
            }
            {/* Function Remark */}
            {abnormalFunction &&
              <Radio.Button value="ABNORMAL">
                ABNORMAL
              </Radio.Button>
            }
          </Space>
        </Radio.Group>
        <Title level={5}>Dataset Name</Title>
        <Tooltip
          placement="topRight"
          title={
            name.trim().length <= 3 &&
            "dataset name must be longer than 4 characters"
          }
          color="red"
        >
          
          <Input
            placeholder="insert dataset name"
            value={name}
            onChange={(e) => onFormChange(e, "name")}
          />
        </Tooltip>
        {type !== "DETECT" && type !== "ABNORMAL" && (
          <>
            <Title level={5}>
              <Tooltip
                placement="right"
                title={"at least two classifications"}
                color="red"
                visible={type !== "DETECT" && classDescriptions.length < 2}
              >
                Classifications
              </Tooltip>
            </Title>
            {classDescriptions.map((t, i) => (
              <ClassContainer key={"class_" + i}>
                <Tooltip
                  placement="topRight"
                  title={t.trim().length <= 0 && "class name must be provided"}
                  color="red"
                >
                  <Input
                    placeholder="insert class name"
                    value={t}
                    onChange={(e) => onFormChange(e, "class_" + i)}
                  />
                </Tooltip>
                <MinusCircleOutlined
                  style={{ fontSize: "1.5rem", marginLeft: "5px" }}
                  className="dynamic-delete-button"
                  onClick={() => handleRemoveClass(i)}
                />
              </ClassContainer>
            ))}
          </>
        )}
        {type !== "DETECT" && type !== "ABNORMAL" && (
          <Button
            type="dashed"
            onClick={handleAddClass}
            style={{ width: "60%" }}
            icon={<PlusOutlined />}
          >
            Add Classification
          </Button>
        )}
      </Space>
    </Modal>
  );
};

export default CreateDatasetForm;
