import { RollbackOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { Vector2d } from 'konva/lib/types';
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Image as KonvaImage, Layer, Stage } from "react-konva";
import short from 'short-uuid';
import { PolygonLabelActionType } from '../../contexts/polygonLabelAction';
import { usePolygonContext } from '../../contexts/polygonLabelContext';
import { AnchorType, PolygonRect, } from '../../contexts/polygonType';
import usePolygonHistory from '../../hooks/usePolygonHistory';
import PolygonAnchor from '../Polygon-Anchor';
import PolygonEditMenu, { EmptyModal } from '../Polygon-Edit-Menu';
import PolygonEditor from '../Polygon-Editor';

const initSize = { width: 1200, height: 800 }

const initMenuProps = {
    show: false,
    x: 0,
    y: 0
};

const findRectSide = (anchors: AnchorType[]) => {
    //console.log(points)
    const pointX = anchors.map(anchor => anchor.x);//取出所有x值
    const pointY = anchors.map(anchor => anchor.y);//取出所有y值
    const minX = Math.min(...pointX);
    const maxX = Math.max(...pointX);
    const minY = Math.min(...pointY);
    const maxY = Math.max(...pointY);
    return {
        left: minX,
        right: maxX,
        top: minY,
        bottom: maxY,
    }
};


const PolygonDrawerCanvas = ({
    imageUrl, onDraw, rectColor, classification
}: {
    imageUrl: string,
    onDraw: boolean,
    rectColor: string,
    classification: number | null
}) => {


    const { polygonState, dispatchPolygon } = usePolygonContext();

    const { currentImage, polygonRects, selectRect, classSummary, classifications } = polygonState;

    const [history, setHistory, undo, dependValue, updatedDependValue, initHistory] = usePolygonHistory(20, currentImage?.id);

    useEffect(() => {
        const rectRecord = JSON.stringify([...polygonRects]);
        if (currentImage?.id !== dependValue) {
            updatedDependValue(currentImage?.id)
            initHistory(rectRecord)
            return
        }
    }, [dependValue, currentImage])


    const onRecordHistory = (rects: PolygonRect[]) => {
        const rectRecord = JSON.stringify([...rects]);
        setHistory(rectRecord);
    }
    const undoBack = () => {
        const historyRectsStr = undo();
        const undoRects = JSON.parse(historyRectsStr) as PolygonRect[];
        if (selectRect) {
            dispatchPolygon({
                type: PolygonLabelActionType.SET_POLYGON_SELECT_ELEMENT,
                rect: null
            })
        };
        dispatchPolygon({
            type: PolygonLabelActionType.SET_POLYGON_DATASET_UPDATED,
            rects: [...undoRects]
        });
    };

    useHotkeys('ctrl+z', (): any => {
        undoBack();
    }, [history]);

    const [menuProps, setMenuProps] = useState(initMenuProps)

    const [imageOriginSize, setImageOriginSize] = useState<{ width: number, height: number }>({ width: 0, height: 0 });//轉相對座標使用

    const [container, setContainer] = useState<[number, number]>([0, 0]);

    const [canvasImage, setCanvasImage] = useState<HTMLImageElement | undefined>(undefined);

    const [emptyRect, setEmptyRect] = useState('');

    //const [onNew, setOnNew] = useState(false);//確認是否創建新的，true的話紀錄polygon point的位置
    const [newPolygon, setNewPolygon] = useState<PolygonRect | null>(null);
    const [nextPoint, setNextPoint] = useState<{ x: number, y: number } | null>(null);

    /**
     * * 轉相對座標 
     * @param anchors 
     * @returns 
     */
    const convertRelatively = (anchors: AnchorType[]) => {
        const [width, height] = container;
        return anchors.map(anchor => {
            return {
                ...anchor,
                x: anchor.x / width,
                y: anchor.y / height
            }
        });
    };

    /**
        * * 轉絕對座標 
        * @param anchors 
        * @returns 
        */
    const convertAbsolute = (anchors: AnchorType[]) => {
        const [width, height] = container;
        return anchors.map(anchor => {
            return {
                ...anchor,
                x: anchor.x * width,
                y: anchor.y * height
            }
        });
    };

    useLayoutEffect(() => {
        const img = new Image()
        img.onload = (e: any) => {
            const { naturalWidth, naturalHeight } = e.target as HTMLImageElement;
            setImageOriginSize({ width: naturalWidth, height: naturalHeight });
            const rh = 800;
            const rw = rh * (naturalWidth / naturalHeight);
            const w = rw > 1000 ? 1000 : rw;
            const h = rh * (w / rw);
            img.width = w;
            img.height = h;
            setContainer([img.width, img.height]);
            setCanvasImage(img);
        };
        img.src = imageUrl;
        return () => {
            img.onload = null;
        };
    }, [imageUrl]);

    /**
     * * 追蹤滑鼠座標不超過指定範圍
     * @param x 
     * @param y 
     * @returns 
     */
    const outRangeCheck = (x: number, y: number) => {
        if (x < 0 || x > canvasImage!.width || y < 0 || y > canvasImage!.height) {
            return false;
        }
        return true;
    };

    /**
     * * 創建矩形的第一個座標位置
     * @param x 
     * @param y 
     */
    const createFirstAnchorPoint = (x: number, y: number) => {
        const newPolygon = {
            id: 'new-drawing',
            anchors: [{ x: x, y: y, index: '0' }],
            isComplete: false,
            visible: true,
            classificationId: classification,
        };
        setNewPolygon(newPolygon);
    };

    /**
     * * 創建下一個座標位置，以利追蹤
     * @param x 
     * @param y 
     */
    const createNextAnchorPoint = (x: number, y: number) => {
        if (newPolygon) {
            const { anchors } = newPolygon;
            const updatedPolygon = {
                ...newPolygon,
                anchors: [...anchors, { x: x, y: y, index: anchors.length.toString() }]
            };
            setNewPolygon(updatedPolygon);
        }
    };

    /**
     * * 更新整體矩形dataset
     * @param polygon 
     */
    const handleUpdatedDataset = (polygon: PolygonRect) => {
        const updatedPolygonDataset = [...polygonRects];
        const index = updatedPolygonDataset.findIndex(rect => rect.id === polygon.id);
        updatedPolygonDataset.splice(index, 1, polygon);
        dispatchPolygon({
            type: PolygonLabelActionType.SET_POLYGON_DATASET_UPDATED,
            rects: [...updatedPolygonDataset]
        });
        onRecordHistory([...updatedPolygonDataset]);
    };

    /**
     * * 更新選擇中的矩形
     * @param anchors 
     */
    const handleUpdatedEditPolygon = (anchors: AnchorType[]) => {
        if (selectRect) {
            const updatedSelectPolygon = {
                ...selectRect,
                anchors: convertRelatively(anchors)
            };
            handleUpdatedDataset(updatedSelectPolygon);
            dispatchPolygon({
                type: PolygonLabelActionType.SET_POLYGON_SELECT_ELEMENT,
                rect: updatedSelectPolygon
            });
        }
    };

    /**
   * * 追蹤滑鼠點下
   * @param e 
   * @returns 
   */
    const handleMouseDown = (e: Konva.KonvaEventObject<MouseEvent>) => {
        if (onDraw) {
            const { x, y } = e.target.getStage()!.getPointerPosition() as Vector2d;
            if (!outRangeCheck(x, y)) return;
            if (newPolygon) {
                createNextAnchorPoint(x, y)
            } else {
                createFirstAnchorPoint(x, y)
            };
            return;
        };
        if (e.target.getAttrs().name === 'Image') {
            setMenuProps(initMenuProps);
            dispatchPolygon({ type: PolygonLabelActionType.SET_POLYGON_SELECT_ELEMENT, rect: null });
            return
        };
    };

    /**
     * * 追蹤滑鼠移動
     * @param e 
     * @returns 
     */
    const handleMouseMove = (e: Konva.KonvaEventObject<MouseEvent>) => {
        if (!onDraw) return;
        if (newPolygon) {
            const { x, y } = e.target.getStage()!.getPointerPosition() as Vector2d;
            setNextPoint({ x, y });
        }
    };

    const handleMouseUp = (e: Konva.KonvaEventObject<MouseEvent>) => {

    };

    /**
     * * 畫完矩形狀態傳至context
     */
    const handlePolygonComplete = () => {
        if (newPolygon) {
            const { anchors } = newPolygon;
            if (anchors.length <= 3) return;
            anchors.pop();//拿掉最後一個項目
            const { left, right, top, bottom } = findRectSide(anchors);
            if ((right - left) < 1 || (bottom - top) < 1) return;
            const completePolygon = {
                ...newPolygon,
                id: short.uuid(),
                anchors: convertRelatively([...anchors]),
                isComplete: true,
            };


            dispatchPolygon({
                type: PolygonLabelActionType.SET_POLYGON_NEW_ELEMENT,
                rects: [...polygonRects, completePolygon]
            });
            setNewPolygon(null);
            setNextPoint(null);

            onRecordHistory([...polygonRects, completePolygon]);
            if (classification === null) {
                setEmptyRect(completePolygon.id)
            };
        }
    };

    /**
     * * 指定當前矩形
     * @param rect 
     */
    const handleSelect = (rect: PolygonRect) => {
        setMenuProps(initMenuProps);
        dispatchPolygon({
            type: PolygonLabelActionType.SET_POLYGON_SELECT_ELEMENT,
            rect: rect
        });
    };

    /**
    * * 按右鍵觸發menu context
    * @param e 
    */
    const handleContext = (e: KonvaEventObject<PointerEvent>) => {
        e.evt.preventDefault()
        const points: number[] = e.target.attrs.points;
        const pointX = points.filter((_, index) => index % 2 === 0);//取出所有x值
        const pointY = points.filter((_, index) => index % 2 !== 0);//取出所有y值
        const minX = Math.min(...pointX);
        const maxX = Math.max(...pointX);
        const minY = Math.min(...pointY);
        const maxY = Math.max(...pointY);
        const side = {
            left: minX,
            right: maxX,
            top: minY,
            bottom: maxY,
        };
        setMenuProps({
            show: true,
            y: (side.top + 100) > container[1] ? side.top - 50 : side.top,
            x: (side.right + 240) > container[0] ? side.right - 300 : side.right
        });
    };

    const handleStageContext = (e: Konva.KonvaEventObject<PointerEvent>) => {
        if (onDraw) {
            setNewPolygon(null);
        }
    };

    useEffect(() => {
        setMenuProps(initMenuProps);
        if (onDraw) {
            return dispatchPolygon({
                type: PolygonLabelActionType.SET_POLYGON_SELECT_ELEMENT,
                rect: null
            });
        } else if (!onDraw && newPolygon) {
            setNewPolygon(null)
        }
    }, [dispatchPolygon, onDraw]);


    const polygonViewer = useMemo(() =>
        selectRect ? polygonRects.filter(rect => rect.id !== selectRect.id) : polygonRects,
        [selectRect, polygonRects])

    return (
        <div style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', top: 10, left: 10, zIndex: 999 }}>
                <Button onClick={() => undoBack()} icon={<RollbackOutlined />} disabled={history.length === 1} />
            </div>
            <EmptyModal rectId={emptyRect} onClose={() => setEmptyRect('')} />
            {selectRect &&
                <PolygonEditMenu rectId={selectRect.id} x={menuProps.x} y={menuProps.y} show={menuProps.show}
                    onClose={() => setMenuProps(initMenuProps)}
                />}
            <Stage
                width={canvasImage?.width}
                height={canvasImage?.height}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseMove={handleMouseMove}
                onContextMenu={handleStageContext}
            //onClick={() => handleCancelSelect()}
            >
                <Layer>
                    <KonvaImage image={canvasImage} name='Image' />
                    {polygonViewer.map(rect => {
                        const target = classifications.find(ele => ele.id === rect.classificationId);
                        const summary = classSummary.find(item => item.class === target?.cid);
                        return <PolygonAnchor
                            rectId={rect.id}
                            key={rect.id}
                            points={convertAbsolute(rect.anchors)}
                            rectColor={summary?.color || rectColor}
                            visible={rect.visible}
                            isComplete={rect.isComplete}
                            //onSelected={!onDraw && Boolean(selectRect) && selectRect!.id === rect.id}
                            handleSelect={() => !onDraw && handleSelect(rect)}
                        />
                    }
                    )}
                    {selectRect &&
                        <PolygonEditor
                            polygon={selectRect}
                            anchors={convertAbsolute(selectRect.anchors)}
                            rectColor={'#b023e1'}
                            handleUpdateEditPolygon={handleUpdatedEditPolygon}
                            handleContext={handleContext}
                        />}
                    {newPolygon &&
                        <PolygonAnchor
                            rectId={newPolygon.id}
                            key={newPolygon.id}
                            visible={true}
                            points={nextPoint !== null ?
                                [...newPolygon.anchors].concat([{ index: '-1', x: nextPoint!.x, y: nextPoint!.y }]) : [...newPolygon.anchors]}
                            rectColor={rectColor}
                            isComplete={newPolygon.isComplete}
                            handlePolygonComplete={handlePolygonComplete}
                            handleContext={() => setNewPolygon(null)}
                        />}
                </Layer>
            </Stage>
        </div>
    );
};

export default PolygonDrawerCanvas


