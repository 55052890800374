import React, { ReactNode, useContext, useEffect, useReducer } from 'react';

import { PolygonLabelAction, PolygonLabelActionType } from './polygonLabelAction';
import { PolygonState } from './polygonType';

const initialState: PolygonState = {
    modalOpen: false,
    datasetId: -1,
    datasetType: '',
    datasetName: '',
    imagesCount: 0,
    currentIndex: 0,
    currentImage: null,
    classifications: [],
    classSummary: [],
    polygonRects: [],
    selectRect: null,
    record: [],
    fetching: false,
    error: false
};


/**
 * 
 * @param state 資料狀態
 * @param action 定義行為
 * @returns 
 */
function PolygonReducer(state: PolygonState, action: PolygonLabelAction): PolygonState {
    console.log(action.type)
    switch (action.type) {
        case PolygonLabelActionType.SET_POLYGON_MODAL_OPEN:
            return {
                ...state,
                modalOpen: true,
                datasetId: action.datasetId,
                datasetType: action.datasetType,
                datasetName: action.datasetName,
                classifications: action.classifications,
                imagesCount: action.imagesCount,
                fetching: true,
                error: false
            };
        case PolygonLabelActionType.SET_POLYGON_CURRENT_IMAGE:
            return {
                ...state,
                currentImage: action.currentImage,
                polygonRects: action.rects,
                fetching: false,
                error: false
            };
        case PolygonLabelActionType.SET_POLYGON_MODAL_CLOSE:
            return { ...state, modalOpen: false };
        case PolygonLabelActionType.SET_POLYGON_NAVIGATE_TO_INDEX:
            return { ...state, currentIndex: action.index, selectRect: null };
        case PolygonLabelActionType.SET_POLYGON_CLASS_SUMMARY:
            return { ...state, classSummary: action.classSummary };
        case PolygonLabelActionType.SET_POLYGON_ELEMENT_CLEAR:
            return { ...state, polygonRects: [], selectRect: null };
        case PolygonLabelActionType.SET_POLYGON_NEW_ELEMENT:
        case PolygonLabelActionType.SET_POLYGON_DATASET_UPDATED:
            return { ...state, polygonRects: action.rects };
        case PolygonLabelActionType.SET_POLYGON_SELECT_ELEMENT:
            return {
                ...state,
                selectRect: action.rect,
            };
        case PolygonLabelActionType.DELETE_POLYGON_BY_ID:
            return {
                ...state,
                polygonRects: state.polygonRects.filter(rect => rect.id !== action.rectId),
                selectRect: null
            }

        case PolygonLabelActionType.SET_POLYGON_VISIBLE_STATE:
            return {
                ...state,
            }
        case PolygonLabelActionType.SET_POLYGON_CLASSIFICATION_ID:
            return {
                ...state,
                polygonRects: state.polygonRects.map(item => {
                    if (item.id === action.rectId) {
                        return {
                            ...item,
                            classificationId: action.classificationId
                        }
                    } else {
                        return { ...item }
                    }
                }),
                selectRect: state.selectRect && {
                    ...state.selectRect,
                    classificationId: action.classificationId
                }
            }
        case PolygonLabelActionType.SET_POLYGON_RESULT_ERROR:
            return {
                ...state,
                error: true
            }
        case PolygonLabelActionType.SET_POLYGON_CLASS_COLORFUL:
            return {
                ...state,
                classSummary: state.classSummary.map(item => {
                    if (item.class === action.classId) {
                        return {
                            ...item,
                            color: action.color
                        }
                    } else {
                        return item
                    }
                })
            }
        case PolygonLabelActionType.SET_POLYGON_DELETE_RECT_BY_ID:
            return {
                ...state, polygonRects: state.polygonRects.filter(item => item.id !== action.rectId)
            }
        case PolygonLabelActionType.SET_POLYGON_IMAGE_COUNT:
            return { ...state, imagesCount: action.imageCount }
        default:
            throw new Error('Unhandled action type');
    }
}

export interface PolygonContextType {
    polygonState: PolygonState;
    dispatchPolygon: React.Dispatch<PolygonLabelAction>;
};

const PolygonContext = React.createContext<PolygonContextType | undefined>(undefined);


export const PolygonProvider = ({ children }: { children: ReactNode }) => {
    const [polygonState, dispatchPolygon] = useReducer(PolygonReducer, initialState);


    useEffect(() => {
        console.log('state:', polygonState)

    }, [polygonState]);

    return (
        <PolygonContext.Provider value={{ polygonState, dispatchPolygon }}>
            {children}
        </PolygonContext.Provider>
    )
};

export const usePolygonContext = (): PolygonContextType => {
    const context = useContext(PolygonContext);
    if (!context) {
        throw new Error('usePolygon must be used within a Provider');
    }
    return context;
};