import styled from '@emotion/styled';
import { Button, Popconfirm } from 'antd';
import { PolygonLabelActionType } from '../../contexts/polygonLabelAction';
import { usePolygonContext } from '../../contexts/polygonLabelContext';
import { PolygonRect } from '../../contexts/polygonType';
import PolygonCropBox from '../Polygon-Crop-Box';
import { offlineUrlExpress } from "../../urls/index";

const ScrollViewer = styled.div`
    width: 100%;
    padding: 12px;
    max-height: 75vh;
    display: flex;
    flex-direction: column;
    gap: 18px;
    overflow-y: auto;
    ::-webkit-scrollbar{
        width: 10px;
    }
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    ::-webkit-scrollbar-thumb {
        background: rgb(100,100,100,0.4);
        border-radius:5px
    }
    ::-webkit-scrollbar-thumb:hover {
        background: rgb(100,100,100,0.8);
    }
`;

const PolygonRectList = ({ labelColor }: { labelColor: string }) => {


    const { polygonState, dispatchPolygon } = usePolygonContext();
    const { polygonRects, currentImage, selectRect } = polygonState;

    const handleDeletePolygon = (polygonId: string) => {
        console.log('delete', polygonId)
        dispatchPolygon({
            type: PolygonLabelActionType.DELETE_POLYGON_BY_ID,
            rectId: polygonId
        })
    };
    const handleVisiblePolygon = (polygon: PolygonRect) => {
        const updatedPolygons = [...polygonRects];

        dispatchPolygon({
            type: PolygonLabelActionType.SET_POLYGON_DATASET_UPDATED,
            rects: updatedPolygons.map(shape => {
                if (shape.id === polygon.id) {
                    return { ...shape, visible: !polygon.visible };
                } else {
                    return shape
                }
            })
        })
    };

    const handleSelectPolygon = (polygon: PolygonRect) => {
        dispatchPolygon({
            type: PolygonLabelActionType.SET_POLYGON_SELECT_ELEMENT,
            rect: polygon
        })
    };

    const confirmClear = () => {
        dispatchPolygon({ type: PolygonLabelActionType.SET_POLYGON_ELEMENT_CLEAR })
    };

    return (
        <div
            style={{
                textAlign: 'center',
                width: 220, border: '0px solid', height: '100%', borderRadius: 10,
                paddingTop: 10,
                boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
            }}
        >
            <div style={{ display: 'flex', gap: 8, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ fontSize: 18, fontWeight: 'bold' }}>
                    Polygon View
                </div>
                <Popconfirm
                    title="Are you sure to clear canvas?"
                    onConfirm={confirmClear}
                    okText="Clear"
                    cancelText="Cancel"
                    disabled={polygonRects.length === 0}
                >
                    <Button type='text' danger disabled={polygonRects.length === 0}>
                        Clear
                    </Button>
                </Popconfirm>

            </div>
            <ScrollViewer >
                {currentImage?.url &&
                    polygonRects.map((polygon, index) =>
                        <PolygonCropBox
                            key={index}
                            index={index}
                            imageSource={`${offlineUrlExpress}/api/get-image?url=${currentImage.url}`}
                            polygon={polygon}
                            labelColor={labelColor}
                            onSelected={polygon.id === selectRect?.id}
                            onDeletePolygon={handleDeletePolygon}
                            onVisiblePolygon={handleVisiblePolygon}
                            onSelectPolygon={handleSelectPolygon}
                        />
                    )}
            </ScrollViewer>
        </div>
    );
}

export default PolygonRectList