
import { Classification, ClassSummary, PolygonImageJson, PolygonRect } from './polygonType';



export enum PolygonLabelActionType {
    FETCH_POLYGON_DATASET = 'FETCH_POLYGON_DATASET',
    SET_POLYGON_MODAL_OPEN = 'SET_POLYGON_MODAL_OPEN',
    SET_POLYGON_MODAL_CLOSE = 'SET_POLYGON_MODAL_CLOSE',
    SET_POLYGON_DATASET_STATE = 'SET_POLYGON_DATASET_STATE',
    SET_POLYGON_CURRENT_IMAGE = 'SET_POLYGON_CURRENT_IMAGE',
    SET_POLYGON_NAVIGATE_TO_INDEX = 'SET_POLYGON_NAVIGATE_TO_INDEX',
    SET_POLYGON_CLASS_SUMMARY = 'SET_POLYGON_CLASS_SUMMARY',
    SET_POLYGON_ELEMENT_CLEAR = 'SET_POLYGON_ELEMENT_CLEAR',
    SET_POLYGON_NEW_ELEMENT = 'SET_POLYGON_NEW_ELEMENT',
    SET_POLYGON_SELECT_ELEMENT = 'SET_POLYGON_SELECT_ELEMENT',
    SET_POLYGON_DATASET_UPDATED = 'SET_POLYGON_DATASET_UPDATED',
    DELETE_POLYGON_BY_ID = 'DELETE_POLYGON_BY_ID',
    SET_POLYGON_VISIBLE_STATE = 'SET_POLYGON_VISIBLE_STATE',
    SET_POLYGON_RECORD = 'SET_POLYGON_RECORD',
    SET_POLYGON_CLASSIFICATION_ID = 'SET_POLYGON_CLASSIFICATION_ID',
    SET_POLYGON_RESULT_ERROR = 'SET_POLYGON_RESULT_ERROR',
    SET_POLYGON_CLASS_COLORFUL = 'SET_POLYGON_CLASS_COLORFUL',
    SET_POLYGON_DELETE_RECT_BY_ID = 'SET_POLYGON_DELETE_RECT_BY_ID',
    SET_POLYGON_IMAGE_COUNT='SET_POLYGON_IMAGE_COUNT'
};

interface SetPolygonOpenModal {
    type: PolygonLabelActionType.SET_POLYGON_MODAL_OPEN,
    datasetId: number,
    datasetType: string,
    datasetName: string,
    classifications:Classification[],
    imagesCount:number,
};

interface SetPolygonCurrentImage { 
    type: PolygonLabelActionType.SET_POLYGON_CURRENT_IMAGE ,
    currentImage: null | PolygonImageJson;
    rects: PolygonRect[];
};

interface SetPolygonCloseModal{
    type: PolygonLabelActionType.SET_POLYGON_MODAL_CLOSE,
   
};

interface SetPolygonNavigateToIndex{
    type:PolygonLabelActionType.SET_POLYGON_NAVIGATE_TO_INDEX,
     index:number
}

interface SetPolygonClassSummary{
    type: PolygonLabelActionType.SET_POLYGON_CLASS_SUMMARY,
    classSummary:ClassSummary[];
};

interface SetPolygonNewElement{
    type:PolygonLabelActionType.SET_POLYGON_NEW_ELEMENT,
    rects: PolygonRect[]
}

interface SetPolygonDatasetClear{
    type:PolygonLabelActionType.SET_POLYGON_ELEMENT_CLEAR,
}

interface SetPolygonSelectElement{
    type: PolygonLabelActionType.SET_POLYGON_SELECT_ELEMENT,
    rect: PolygonRect|null
}

interface DeletePolygonById{
    type: PolygonLabelActionType.DELETE_POLYGON_BY_ID
    rectId:string
}
interface SetPolygonDatasetUpdated{
    type: PolygonLabelActionType.SET_POLYGON_DATASET_UPDATED,
     rects: PolygonRect[]
}

interface SetPolygonVisibleState {
    type: PolygonLabelActionType.SET_POLYGON_VISIBLE_STATE,
    rectId:string
}

interface SetPolygonClassificationId{
    type: PolygonLabelActionType.SET_POLYGON_CLASSIFICATION_ID,
    rectId: string,
    classificationId:number
}

interface SET_POLYGON_RESULT_ERROR{
    type: PolygonLabelActionType.SET_POLYGON_RESULT_ERROR
}

interface SET_POLYGON_CLASS_COLORFUL{
    type: PolygonLabelActionType.SET_POLYGON_CLASS_COLORFUL,
    classId:number,
    color:string|undefined
}

interface SET_POLYGON_DELETE_RECT_BY_ID{
    type: PolygonLabelActionType.SET_POLYGON_DELETE_RECT_BY_ID,
    rectId:string
}

interface SET_POLYGON_IMAGE_COUNT{
    type: PolygonLabelActionType.SET_POLYGON_IMAGE_COUNT,
    imageCount:number
}

export type PolygonLabelAction =
    | SetPolygonOpenModal
    | SetPolygonCurrentImage
    | SetPolygonCloseModal
    | SetPolygonNavigateToIndex
    | SetPolygonClassSummary
    | SetPolygonDatasetClear
    | SetPolygonNewElement
    | SetPolygonSelectElement
    | DeletePolygonById
    | SetPolygonDatasetUpdated
    | SetPolygonVisibleState
    | SetPolygonClassificationId
    | SET_POLYGON_RESULT_ERROR
    | SET_POLYGON_CLASS_COLORFUL
    | SET_POLYGON_DELETE_RECT_BY_ID
    | SET_POLYGON_IMAGE_COUNT;
