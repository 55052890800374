import { Button, Popover } from 'antd';
import { PolygonLabelActionType } from '../../contexts/polygonLabelAction';
import { usePolygonContext } from '../../contexts/polygonLabelContext';
import { ClassSummary } from '../../contexts/polygonType';
import PolygonClassColorful from '../Polygon-Class-Colorful';

const PolygonClassPicker = ({
    classItem, onClass, onClick

}: {
    classItem: ClassSummary,
    onClass: boolean,
    onClick: () => void
}) => {

    const { polygonState, dispatchPolygon } = usePolygonContext();
    const { classSummary } = polygonState;

    const colors = classSummary.filter(item => item.color && item.color !== 'none').map(item => item.color!);

    const classColor = (classItem.color && classItem.color !== 'none') ? classItem.color : 'gray';

    const handlePickColor = (color: string | undefined) => {
        dispatchPolygon({
            type: PolygonLabelActionType.SET_POLYGON_CLASS_COLORFUL,
            classId: classItem.class,
            color: color
        })
    };
    return (
        <div
            style={{
                width: '90%', display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}>
            <Button
                danger={onClass}
                onClick={() => onClick()}
                style={{ width: 150 }}
                size='small'
            >
                {classItem.classlabel} {classItem.count} / {classItem.imagecount}
            </Button>

            {classItem.class > 0 &&
                <Popover
                    content={<PolygonClassColorful
                        currentColors={colors}
                        onPickColor={handlePickColor}
                    />} title="Class Color Picker" trigger="click"
                >
                    <Button size='small' >
                        <div style={{ width: 12, height: 12, borderRadius: 6, background: classColor }} />
                    </Button>
                </Popover>}

        </div>
    )
}

export default PolygonClassPicker