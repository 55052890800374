import { Button, Drawer, message, Popover } from "antd";

import { ExceptionOutlined, FullscreenOutlined, LoadingOutlined } from "@ant-design/icons";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { fetchPolygonClassSummary, fetchPolygonDatasetById, queryImageCount } from "../../contexts/polygonAnyCreator";
import { PolygonLabelActionType } from "../../contexts/polygonLabelAction";
import { usePolygonContext } from "../../contexts/polygonLabelContext";
import { PolygonImageJson, PolygonRect } from "../../contexts/polygonType";
import PolygonController from "../Polygon-Controller";
import PolygonDrawerCanvas from "../Polygon-Drawer-Canvas";
import PolygonImageInformation from "../Polygon-Image-Information";
import PolygonRectList from "../Polygon-Rect-List";
import { offlineUrlExpress } from "../../urls/index";

export interface OptionType {
    filterLabeled: boolean | undefined;
    classed: string | undefined;
    isImport: boolean | undefined;
    filterSource: string;
};

export interface MainOption {
    locked: boolean;
    onDraw: boolean,
    rectColor: string;
    defaultClassification: number | null
};

export const colorType = {
    green: '#19ab31',
    blue: '#386afa',
    yellow: '#f39c12'
};

const initGlobalOption: OptionType = {
    filterLabeled: undefined,
    classed: undefined,
    isImport: undefined,
    filterSource: 'All'
};
const initMainOption: MainOption = {
    locked: false,
    onDraw: false,
    rectColor: colorType.green,
    defaultClassification: null
};

const PolygonMainDrawer = () => {
    const { polygonState, dispatchPolygon } = usePolygonContext();
    const { datasetId, fetching, currentIndex, modalOpen, classifications } = polygonState;

    const [options, setOptions] = useState<OptionType>(initGlobalOption);

    const [dependDatasetId, setDependDatasetId] = useState(0);

    const [mainOption, setMainOption] = useState<MainOption>(initMainOption);

    const transformRef = useRef<ReactZoomPanPinchRef | null>(null);

    const handleMainOption = (key: string, value: string | boolean | number) => {
        const updatedOption = { ...mainOption, [key]: value };
        setMainOption(updatedOption);
    };

    const handleGlobalOption = (option: OptionType) => {
        setOptions(option);
        dispatchPolygon({
            type: PolygonLabelActionType.SET_POLYGON_NAVIGATE_TO_INDEX,
            index: 0
        })
    };

    const FetchPolygonClassSummaryAsync = useCallback(async (datasetId: number) => {
        await fetchPolygonClassSummary(datasetId).then(data => {
            if (data.success && data.data) {
                return dispatchPolygon({
                    type: PolygonLabelActionType.SET_POLYGON_CLASS_SUMMARY,
                    classSummary: data.data
                })
            } else {
                message.error(data.message)
            }
        }).catch(err => {
            message.error(err)
        });
    }, [dispatchPolygon]);

    const QueryPolygonImageCountAsync = useCallback(async (option: OptionType) => {
        const { filterLabeled, classed, isImport, filterSource } = option;
        await queryImageCount(datasetId, filterLabeled, classed, isImport, filterSource).then(res => {
            if (res.success && res.data) {
                dispatchPolygon({
                    type: PolygonLabelActionType.SET_POLYGON_IMAGE_COUNT,
                    imageCount: res.data
                })
            }
        }).catch(err => message.error(err))
    }, [datasetId, dispatchPolygon]);

    const FetchPolygonCurrentImageAsync = useCallback(async (index: number, option: OptionType) => {
        const { filterLabeled, classed, isImport, filterSource } = option;
        await fetchPolygonDatasetById(datasetId, index, filterLabeled, classed, isImport, filterSource).then(data => {
            if (data.success && data.data) {
                const imageData = data.data as PolygonImageJson;
                const labels = imageData.datasetDetectLabels;
                //---將存儲的label資料轉換成前端用的格式---//
                const anchorLabels: PolygonRect[] = labels.map(item => {
                    return {
                        id: item.id.toString(),
                        classificationId: item.classificationId,
                        anchors: item.region.map((reg, index) => {
                            return {
                                index: index.toString(),
                                x: reg.x, y: reg.y
                            }
                        }),
                        isComplete: true,
                        visible: true
                    }
                });
                dispatchPolygon({
                    type: PolygonLabelActionType.SET_POLYGON_CURRENT_IMAGE,
                    currentImage: imageData,
                    rects: anchorLabels
                });

            } else if (data.success && !data.data) {
                throw new Error('no results')
            } else {
                throw new Error(data.message)
            }
        }).catch(err => {
            dispatchPolygon({
                type: PolygonLabelActionType.SET_POLYGON_RESULT_ERROR,
            });
            message.error(err)
        });
    }, [dispatchPolygon, datasetId])

    useEffect(() => {
        if (datasetId === dependDatasetId) {
            return
        } else {
            setMainOption(initMainOption)
            setOptions(initGlobalOption);
            setDependDatasetId(datasetId);
            FetchPolygonClassSummaryAsync(datasetId);
        };
    }, [FetchPolygonClassSummaryAsync, datasetId, modalOpen, dependDatasetId]);

    useEffect(() => {
        if (datasetId && modalOpen) {
            FetchPolygonCurrentImageAsync(currentIndex, options);
            QueryPolygonImageCountAsync(options);
            transformRef.current?.resetTransform();
        }
    }, [datasetId,
        modalOpen,
        currentIndex,
        options,
        FetchPolygonCurrentImageAsync,
        QueryPolygonImageCountAsync]);


    return (
        <Drawer
            title={
                <div style={{ display: 'flex', alignItems: 'center', paddingTop: 10, justifyContent: 'space-between' }}>
                    <h3>Image Labeling</h3>
                    <Popover
                        content={<PolygonImageInformation state={polygonState} />}
                        title="Data Information" trigger="click" placement="topLeft">
                        <Button icon={<ExceptionOutlined />}>Information</Button>
                    </Popover>

                </div>
            }
            width={'100%'}
            open={polygonState.modalOpen}
            closable={true}
            onClose={() => dispatchPolygon({ type: PolygonLabelActionType.SET_POLYGON_MODAL_CLOSE })}
        >
            <div style={{ display: 'flex', gap: 20, justifyContent: 'center' }}>
                <div id='controller'>
                    <PolygonController
                        globalOption={options}
                        option={mainOption}
                        handleMainOption={handleMainOption}
                        handleGlobalOption={handleGlobalOption}
                    />
                </div>
                <div style={{
                    boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px', padding: 10,
                    position: 'relative', width: 1000, height: '80vh', display: 'flex', justifyContent: 'center', borderRadius: 10, alignItems: 'cenetr'
                }}>
                    {fetching &&
                        <div>
                            <LoadingOutlined style={{ fontSize: 36 }} />
                        </div>
                    }
                    {!fetching && <TransformWrapper
                        ref={transformRef}
                        initialScale={1}
                        initialPositionX={0}
                        initialPositionY={0}
                        wheel={{ disabled: false }}
                        pinch={{ disabled: false }}
                        doubleClick={{ disabled: true }}
                        maxScale={10}
                        disabled={mainOption.onDraw || polygonState.selectRect !== null || mainOption.locked}
                    >
                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                            <React.Fragment>
                                <div style={{ position: 'absolute', right: 0, top: 20, zIndex: 99, height: 50, width: 50 }}>
                                    <Button
                                        onClick={() => resetTransform()}
                                        icon={<FullscreenOutlined style={{ fontSize: 32 }} />} type='text' />
                                </div>
                                <TransformComponent>
                                    {polygonState.currentImage &&
                                        <PolygonDrawerCanvas
                                            imageUrl={`${offlineUrlExpress}/api/get-image?url=${polygonState.currentImage?.url}`}
                                            rectColor={mainOption.rectColor}
                                            onDraw={mainOption.onDraw}
                                            classification={mainOption.defaultClassification}
                                        />}

                                </TransformComponent>
                            </React.Fragment>
                        )}
                    </TransformWrapper>}

                </div>
                <div style={{ height: '80vh' }}>
                    <PolygonRectList labelColor={mainOption.rectColor} />
                </div>

            </div>
        </Drawer>
    )
}

export default PolygonMainDrawer;

