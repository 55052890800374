import "antd/dist/antd.css";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "./states";

import App from "./app";
import { PolygonProvider } from "./contexts/polygonLabelContext";




ReactDOM.render(
  <Provider store={store}>
    <PolygonProvider>
      <App />
    </PolygonProvider>
  </Provider>,
  document.getElementById("root")
);
