import { useEffect, useRef, useState } from "react";
import { Circle, Group, Line } from "react-konva";

import { KonvaEventObject } from "konva/lib/Node";
import { AnchorType } from "../../contexts/polygonType";


// export const dragBoundFunc = (stageWidth, stageHeight, vertexRadius, pos) => {
//     let x = pos.x;
//     let y = pos.y;
//     if (pos.x + vertexRadius > stageWidth) x = stageWidth;
//     if (pos.x - vertexRadius < 0) x = 0;
//     if (pos.y + vertexRadius > stageHeight) y = stageHeight;
//     if (pos.y - vertexRadius < 0) y = 0;
//     return { x, y };
// };

const findRectSide = (points: number[]) => {
    //console.log(points)
    const pointX = points.filter((_, index) => index % 2 === 0);//取出所有x值
    const pointY = points.filter((_, index) => index % 2 !== 0);//取出所有y值
    const minX = Math.min(...pointX);
    const maxX = Math.max(...pointX);
    const minY = Math.min(...pointY);
    const maxY = Math.max(...pointY);
    return {
        left: minX,
        right: maxX,
        top: minY,
        bottom: maxY,
    }
};
const PolygonAnchor = ({
    rectId, points, rectColor, isComplete, onSelected, visible,
    handleSelect,
    handlePolygonComplete,
    handleContext,
}: {
    rectId: string, points: AnchorType[], rectColor: string, isComplete: boolean, visible: boolean,
    onSelected?: boolean,
    onEdit?: boolean,

    handleSelect?: () => void,
    handlePolygonComplete?: () => void,
    handleContext?: (e: KonvaEventObject<PointerEvent>) => void,
}) => {

    const polygonRef = useRef<any>();
    const trRef = useRef<any>();

    const [circleRect, setCircleRect] = useState({
        strokeWidth: 2,
        fill: "transparent"
    });

    useEffect(() => {
        if (onSelected && trRef && trRef.current) {
            trRef.current.nodes([polygonRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [onSelected]);


    const handleAnchorContext = (e: KonvaEventObject<PointerEvent>) => {
        e.evt.preventDefault()
        return handleContext && handleContext(e)
    };
    return (
        <Group visible={visible}>
            <Line
                id={rectId}
                ref={polygonRef}
                strokeWidth={3}
                scaleStrokeEnabled={false}
                onClick={handleSelect}
                onContextMenu={handleAnchorContext}
                stroke={rectColor}
                opacity={1}
                closed={isComplete}
                shadowBlur={1}
                shadowColor={rectColor}
                points={points.flatMap((point) => [point.x, point.y])}
            />

            {!isComplete && points.map((point, index) =>
                <Circle
                    key={rectId + index * 3}
                    id={point.index.toString()}
                    x={point.x}
                    y={point.y}
                    stroke="#eee"
                    radius={5}
                    fill={rectColor}
                />
            )}
            {points[0] && !isComplete && handlePolygonComplete && (
                <Circle
                    key={rectId + '-origin-anchor'}
                    id='origin-anchor'
                    x={points[0].x}
                    y={points[0].y}
                    radius={12}
                    stroke={rectColor}
                    fill={circleRect.fill}
                    strokeWidth={circleRect.strokeWidth}
                    onMouseOver={() => {
                        document.body.style.cursor = "pointer";
                        if (points.length <= 3) {
                            setCircleRect({
                                strokeWidth: 2 + 2,
                                fill: "red"
                            })
                        } else {
                            setCircleRect({
                                strokeWidth: 2 + 2,
                                fill: rectColor
                            });
                        }
                    }}
                    onMouseOut={() => {
                        document.body.style.cursor = "default";
                        setCircleRect({
                            strokeWidth: 2,
                            fill: "transparent"
                        });
                    }}
                    onClick={() => {
                        document.body.style.cursor = "default";
                        if (points.length > 3) {
                            return handlePolygonComplete()
                        }
                    }}
                />
            )}
        </Group>
    )
}

export default PolygonAnchor