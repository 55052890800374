import axios from "axios";

import { offlineUrlExpress } from "../urls";
import { PolygonImageJson, PolygonRect } from "./polygonType";


const fetchPolygonDatasetById = async(
    datasetId: number, currentIndex: number, labeled?: boolean, classed?: string, isImport?: boolean, source?: string
) =>{ 
    try { 
        const { data } = await axios.get<PolygonImageJson[]>(
            `${offlineUrlExpress}/api/dataset-images/${datasetId}`,
            {
                params: {
                    skip: currentIndex,
                    take: 1,
                    labeled: labeled,
                    class: classed,
                    isimport: isImport,
                    source: source
                },
            }
        );      
        return {
            success: true,
            message:'fetch success',
            data:data[0]
        }
    } catch (err: any) {
        return {
            success: false,
            message: 'fetch failed',
            data:null
        }
    }
};

export const queryImageCount = async (
    datasetId: number, labeled: boolean | undefined, classed?:string,isImport?:boolean, source?:string
) => { 
    try {
        const { data } = await axios.get<number>(
            `${offlineUrlExpress}/api/dataset-images/${datasetId}/count`,
            {
                params: {
                    labeled: labeled,
                    class: classed,
                    isimport: isImport,
                    source: source
                },
            }
        );
        return {
            success: true,
            message:'fetch success',
            data:data
        }
    } catch (err) {
        return {
            success: false,
            message: 'fetch failed',
            data:null
        }
    }
};

const fetchPolygonClassSummary =async(datasetId:number)=>{
    try {
    const url =  `${offlineUrlExpress}/api/getdatasetclassessummary/${datasetId}`
    const resclass = await axios.get(url);
    const jData = await resclass.data;
    console.log(jData)
    return {
            success: true,
            message:'fetch success',
            data:jData
        }
   }catch(err:any){
     return {
            success: false,
            message: 'fetch failed',
            data:null
        }
  }
};



const createLabels = (rects:PolygonRect[]) => { 
    return rects.map((rect, index) => {
        return {
            id: index,
            type: 'Polygon',
            classificationId: rect.classificationId,
            isImport: false,
            region: rect.anchors.map(({ x, y }) => {
                return {
                    x, y
                }
            })
        };
    });
};

const saveDetectLabels = async (datasetImageId: number, polygonRect: PolygonRect[]) => { 
    
    const polyLabels = createLabels(polygonRect);
    try {
        await axios.post(
        `${offlineUrlExpress}/api/dataset-images/detect-labels/${datasetImageId}`,
        {
          coordinates: polyLabels,
        }
        );
        return {
            success: true,
            message:'save success',
            data:null
        }
    } catch (err: any) {
        return {
            success: true,
            message:'save success',
            data:null
        }
    }
    
};

export {
    fetchPolygonClassSummary, fetchPolygonDatasetById, saveDetectLabels
};



    